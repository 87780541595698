/*--------------------------------------------------------------
                  #   PUBLICATIONS
--------------------------------------------------------------*/

main {
    font-size: 1rem;
    background-color: var(--backgroundColor);
    width: 100%;
}

/* PAGE ONE */
.banner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.banner h1 {
    font-family: "Poppins", sans-serif;
    font-size: 3.5rem;
    font-weight: 900;
    animation: reveal 3000ms ease-in-out forwards 200ms, flicker 2s ease 4;
}

.banner h1:hover {
    animation: shake 0.5s;
    animation-iteration-count: infinite;
}


/* PAGE TWO */
.page-two {
    min-height: 50vh;
    background-color: var(--backgroundColor);
    padding: 1rem;
}

.publications {
    margin-bottom: 4rem;
}

.mycard {
    width: 100%;
    height: 320px;
    color: #473e6a;
    box-shadow: 0 0 0 2px rgb(230, 229, 229), inset 0 2px 0 rgba(0, 0, 0, 0.08),
        0 3px 0 2px rgba(0, 0, 0, 0.05);
    overflow: hidden;
}

.mycard-img {
    width: 100%;
    height: 100%;
    background-color: var(--backgroundColor);
    /* fallback color */
    background-position: center;
    background-size: cover;
    transition: all 0.4s ease-in-out;
    filter: grayscale(20%);
    opacity: 1;
}

.mycard:hover .mycard-img,
.mycard:focus .mycard-img {
    transform: scale(1.02);
    opacity: 1;
}

.mycard:hover .mycard-img-overlay {
    color: white;
    background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 1));
    height: 100%;
    padding: .8rem;
}

.mycard-img-overlay>h1,
.mycard-img-overlay>p,
.mycard-img-overlay .button,
.mycard-img-overlay .project-badges {
    visibility: hidden;
}

.mycard-img-overlay:hover h1,
.mycard-img-overlay:hover p,
.mycard-img-overlay:hover .button,
.mycard-img-overlay:hover .project-badges {
    visibility: visible;
}

.mycard:hover .mycard-img-overlay p {
    font-size: 1rem;
    padding: 0;
}

.mycard-img-overlay h1 {
    text-shadow: 4px 4px 4px #000000;
    margin-top: 1.5rem;
}

.mycard-img-overlay .button {
    margin: 0 3rem;
    background-color: transparent;
    font-size: 1rem;
    padding: 0.3rem;
    text-transform: capitalize;
}

.project-badges .badge {
    margin: 5px;
    font-size: 0.6rem;
    text-transform: uppercase;
}


/* MEDIA QUERIES */
@media screen and (min-width: 768px) {

    .mycard-img-overlay .button {
        margin: 0 2rem;
        background-color: transparent;
        font-size: .8rem;
        padding: 0.3rem;
        text-transform: capitalize;
    }
}

@media only screen and (min-width: 1024px) {

    .mycard-img-overlay h1 {
        font-size: 1.7rem;
        padding: 0;
    }

    .mycard:hover .mycard-img-overlay .card-text {
        font-size: 1.1rem;
        padding: 0;
    }

    .mycard-img-overlay .button {
        font-size: 1.2rem;
        padding: 0.4rem;
    }

    .project-badges .badge {
        font-size: 0.8rem;
    }
}