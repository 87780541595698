/*--------------------------------------------------------------
                  #     CONTACT FORM
--------------------------------------------------------------*/

.contact-form-card {
    text-align: left;
    padding: 30px;
    padding-bottom: 10px;
    width: 100%;
    border-radius: 0 4rem;
    box-shadow: 0 -4px 4px var(--primaryColor), 0 4px 4px var(--primaryColor);
}

.contact-form-card h1 {
    font-size: 1.2rem;
}

.fcf-form-group {
    margin-bottom: 1rem;
}

.fcf-input-group {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: stretch;
    align-items: center;
    width: 100%;
}

.fcf-form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--primaryTextColor);
    background-color: var(--backgroundColor);
    background-clip: padding-box;
    border: 1px solid #ced4da;
    outline: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.fcf-form-control:focus {
    border: 1px solid var(--primaryColor);
}

textarea.fcf-form-control {
    height: auto;
}

label.fcf-label {
    display: inline-block;
    margin-bottom: 0.5rem;
}

label.fcf-label .asterisks {
    color: red;
}

#fcf-button {
    display: inline-block;
    font-weight: 400;
    color: white;
    background-color: transparent;
    border: 1px solid var(--primaryColor);
    line-height: 1.5;
    border-radius: 0.5rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    margin-top: 0.5rem;
}

@media (prefers-reduced-motion: reduce) {
    .fcf-btn {
        transition: none;
    }
}

#fcf-button:hover {
    color: var(--green);
    -ms-transform: scale(1.2);
    /* IE 9 */
    -webkit-transform: scale(1.2);
    /* Safari 3-8 */
    transform: scale(1.1);
}

.fcf-btn:focus,
.fcf-btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.form-error {
    color: palevioletred;
}

#notify-toast {
    color: #fff;
    border-radius: 0.5rem;
    text-align: center;
    font-size: 1rem;
    border: 1px solid var(--backgroundColor);
    background-color: var(--primaryColor);
}