/*--------------------------------------------------------------
                  # NAVBAR
--------------------------------------------------------------*/
.navbar {
    width: 100vw;
    font-size: 1rem;
    text-shadow: 4px 4px 4px #000000;
    font-weight: 700;
    background-color: var(--backgroundColor);
  }
  
  .active {
    border-radius: 0 0.7rem;
    box-shadow: 0 -2px 2px var(--primaryColor), 0 2px 2px var(--primaryColor);
  }
  
  