/*--------------------------------------------------------------
                  # LOADER
--------------------------------------------------------------*/
.loaderBackground {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: var(--primaryTextColor);
    background-color: var(--backgroundColor);
  }
  
  