/*--------------------------------------------------------------
                  # HOME
--------------------------------------------------------------*/
.home-page {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7));
    overflow: hidden;
}

.hero {
    text-shadow: 4px 4px 4px #000000;
    font-family: Poppins, sans-serif;
    line-height: 1;
}

.hero h1 {
    font-size: 2em;
    font-weight: 700;
}

.hero h2 {
    font-size: .9em;
}

.hero h3 {
    font-size: .7em;
}

.hero h2,
.hero h3 {
    margin: 0.8rem 0;
}


.icon-box {
    backdrop-filter: blur(8px);
    position: relative;
    color: white;
    font-size: 24px;
    height: 40px;
    width: 40px;
    padding: 5px;
    display: inline-block;
    margin: 5px;
    text-align: center;
    cursor: pointer;
    -webkit-transition: all ease 0.5s;
    -moz-transition: all ease 0.5s;
    transition: all ease 0.5s;
    /* Glow effect */
    -webkit-box-shadow: 0 0 25px rgb(4, 37, 1);
    -moz-box-shadow: 0 0 25px rgb(4, 37, 1);
    box-shadow: 0 0 25px rgb(4, 37, 1);
}

.icon-box:hover {
    box-shadow: inset 0 50px 0 0 var(--primaryColor);
    color: white;
    transform: scale(1.3);
}