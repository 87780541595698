/*--------------------------------------------------------------
                  # ABOUT
--------------------------------------------------------------*/
.about {
    min-height: 100vh;
    font-size: 1rem;
    background-color: var(--backgroundColor);
    padding: 2rem 0;
}

.tech-title,
.header h1 {
    font-family: Poppins, sans-serif;
}

h3 {
    text-transform: capitalize;
}

/* Tools Tag Cloud Animation */
@keyframes blinker {
    50% {
        opacity: 0;
    }
}

/* -------------ABOUT ME----------- */
.aboutMeSection {
    margin: 2rem 1rem;
}

.aboutDetails {
    line-height: 1.2;
    text-align: left;
    margin-top: 1rem;
}

.lastParagraph {
    text-align-last: center;
}

.header h1 {
    font-size: 1.5rem;
    line-height: 1;
    padding: 0.3rem;
    animation: reveal 3000ms ease-in-out forwards 200ms, flicker 2s ease 4;
    font-weight: 700;
}

.avatarLoader {
    min-height: 30vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.avatar {
    border-radius: 0.5rem;
    filter: grayscale(80%);
    transition: all 0.4s ease-in-out;
}


/* --------TECHNOLOGIES & TOOLS--------- */
.othertech {
    margin: 1rem 0;
}

.techdiv {
    margin-top: 3rem;
    margin-bottom: 2rem;
}

.techdiv p {
    line-height: 1;
}

.tech-logos {
    display: inline-block;
}

.techdiv .logos {
    height: 40px;
    margin: 1rem;
}

/* -------------LANGUAGES----------- */
.languages-section {
    margin: 4rem 0;
}

.tech-score .filled {
    color: #18d26e;
}


/* Tablets 768 - 1023 */
@media screen and (min-width: 768px) {

    /* ABOUT PAGE */
    .aboutDetails {
        margin-top: 0;
    }

    .techdiv {
        margin-top: 4rem;
    }

    .techdiv p {
        margin-top: 0.8rem;
    }

    .languages-section {
        margin: 3rem 0;
    }
}


/* Laptops 1024 - 1279*/
@media screen and (min-width: 1024px) {

    .about {
        font-size: 1.2rem;
    }

    .aboutDetails {
        line-height: 1.3;
        padding: 1.5rem;
    }

    .techdiv p {
        font-size: 1.1rem;
        margin-bottom: 0.5rem;
        line-height: 1.3;
    }

    .fun-facts {
        padding: 0 3rem;
    }

    .fun-facts h1 {
        font-size: 1.7rem;
    }

}


/* Larger Screens 1280+ */
@media screen and (min-width: 1280px) {

    .about {
        font-size: 1.4rem;
    }

    .aboutDetails {
        line-height: 1.3;
    }

    .techdiv p {
        font-size: 1.3rem;
        margin-bottom: 1rem;
    }
}