/*--------------------------------------------------------------
                  # SCROLL TO TOP
--------------------------------------------------------------*/
.top-to-btm {
    position: relative;
}

.arrow-icon-position {
    position: fixed;
    bottom: 40px;
    right: 25px;
    z-index: 20;
}

.arrow-icon-style {
    /* background-color: white;
    border: 2px solid #fff; */
    border-radius: 50%;
    height: 50px;
    width: 50px;
    cursor: pointer;
    animation: movebtn 3s ease-in-out 2;
    transition: all .5s ease-in-out;
    opacity: 0.6;
}

.arrow-icon-style:hover {
    animation: none;
    /* background: white; */
    opacity: 1;
    transform: scale(1.2);
}