@font-face {
  font-family: "Poppins";
  src: url(../fonts/Poppins-Regular.ttf) format("truetype");
  font-display: swap;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  line-height: 1.2;
}

body {
  height: 100%;
  width: 100%;
  font-family: sans-serif, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--primaryTextColor);
  text-align: center;
  background-color: var(--backgroundColor);
  overflow-x: hidden;
}

a {
  color: var(--linkColor);
}

a:hover {
  color: #35e888;
  text-decoration: none;
}

.app {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

/* Flex container */
.container {
  display: flex;
  -webkit-display: box;
  -moz-display: box;
  -ms-display: flexbox;
  -webkit-display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
}

/* Used in both portfolio and publications */
.flex-container {
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
  /* center and flex wrap in row direction */
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-direction: row;
  flex-direction: row;
  justify-content: center;
}



/*--------------------------------------------------------------
                  # SCROLL DOWN BTN
--------------------------------------------------------------*/
.scrollDown {
  position: absolute;
  left: 50%;
  bottom: 30px;
  transform: translate(-50%, -50%);
  opacity: 0.5;
  /* animation: movebtn 3s ease-in-out 2; */
}

.scrollDown:hover {
  cursor: pointer;
  opacity: 1;
  transition: 0.5s;
}




/*--------------------------------------------------------------
                  # ANIMATIONS
--------------------------------------------------------------*/

@keyframes reveal {
  80% {
    letter-spacing: 3px;
  }

  100% {
    background-size: 220% 220%;
  }
}

@keyframes flicker {

  0%,
  19%,
  21%,
  23%,
  25%,
  54%,
  56%,
  100% {
    text-shadow: 0.2rem 0.2rem 1rem #fff,
      0 0 1rem #18d26e;
  }

  20%,
  24%,
  55% {
    text-shadow: none;
    box-shadow: none;
  }
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }

  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }

  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }

  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }

  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }

  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }

  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }

  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }

  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

@keyframes updown {

  50% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(-100%);
  }
}

@keyframes movebtn {
  0% {
    transform: translateY(0px);
  }

  25% {
    transform: translateY(20px);
  }

  50% {
    transform: translateY(0px);
  }

  75% {
    transform: translateY(-20px);
  }

  100% {
    transform: translateY(0px);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.5;
  }
}

/* RESPONSIVE NOTES */
/* My Breakpoints */
/* sm=480 - mobile
   md=768 - tablets
   lg=1024 - laptops
   xl=1280 - larger screens */

/* Mobile first responsiveness :) */
/* Phone 480 - 767 */
