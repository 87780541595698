main {
    font-size: 1rem;
    min-height: 100vh;
    background-color: var(--backgroundColor);
}

.contact-page {
    margin: 5rem 0;
}

.contact-page p {
    font-size: 1.1rem;
    margin-bottom: 1.5rem;
}