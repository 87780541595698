/*--------------------------------------------------------------
                  # RESUME
--------------------------------------------------------------*/
main {
    background: var(--backgroundColor);
    font-size: 1rem;
}

/* PAGE ONE */
.page-one {
    height: 100vh;
    width: 100%;
}

.banner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.banner h1 {
    font-family: "Poppins", sans-serif;
    font-size: 3.5rem;
    font-weight: 900;
    animation: reveal 3000ms ease-in-out forwards 200ms, flicker 2s ease 4;
    margin-bottom: 1.5rem;
}

.banner h1:hover {
    animation: shake 0.5s;
    animation-iteration-count: infinite;
}

.page-one .download-btn {
    transition: transform 0.1s;
    color: white;
    border-radius: 0 0.7rem;
    box-shadow: 0 -3px 3px var(--primaryColor), 0 3px 3px var(--primaryColor);
}

.page-one .download-btn:hover {
    background-color: transparent;
    -ms-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
}

.page-one .download-btn:active {
    background-color: #347536;
    box-shadow: 0 5px #666;
    transform: translateY(4px);
}


/* PAGE TWO */
.page-two {
    min-height: 50vh;
    background-color: var(--backgroundColor);
}

.page-two h1 {
    font-size: 1.5rem;
    line-height: 1;
    font-weight: 900;
    font-family: Poppins, sans-serif;
    color: var(--primaryTextColor);
    text-shadow: 3px 3px 3px #000000;
}

.page-two .title-description {
    color: var(--primaryTextColor);
    margin-bottom: 1rem;
    padding: 1rem;
}

#resume {
    width: 100vw;
    margin: 5rem 0;
}

#accordion-card {
    border: 1px solid var(--backgroundColor);
    border-bottom: 1px solid white;
}

#resume .card-header,
#resume .card-body {
    background-color: var(--backgroundColor);
}

#resume .card-body {
    min-height: 100vh;
}

#accordion-btn {
    background-color: transparent;
    color: var(--primaryTextColor);
    border-radius: 0 0.7rem;
    box-shadow: 0 -2px 2px var(--primaryColor), 0 2px 2px var(--primaryColor);
}

#accordion-btn:hover {
    transform: scale(1.2);
}


/* TIMELINE */
.timeline {
    max-width: 1080px;
    margin: 30px auto;
    padding: 0 0.5rem;
    position: relative;
    font-family: Poppins, sans-serif;
}

.timeline .center-line {
    position: absolute;
    height: 100%;
    width: 4px;
    background: #bdf8d8;
    left: 50%;
    top: 20px;
    transform: translateX(-50%);
}

.timeline .row {
    display: flex;
}

.timeline .row-1 {
    justify-content: flex-start;
}

.timeline .row-2 {
    justify-content: flex-end;
}

.timeline .row section {
    background: var(--backgroundColor);
    border-radius: 10px;
    width: calc(50% - 40px);
    padding: 20px;
    border: 2px solid var(--primaryColor);
    color: white;
    position: relative;
}

.timeline .row section::before {
    position: absolute;
    content: "";
    height: 15px;
    width: 15px;
    background: #d0ecde;
    top: 28px;
    z-index: -1;
    transform: rotate(45deg);
}

.row-1 section::before {
    right: -7px;
}

.row-2 section::before {
    left: -7px;
}

.row section .icon,
.center-line .scroll-icon {
    position: absolute;
    background: #18d26e;
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 40px;
    border-radius: 50%;
    color: #fff;
    font-size: 17px;
    box-shadow: 0 0 0 4px rgb(230, 229, 229), inset 0 2px 0 rgba(0, 0, 0, 0.08),
        0 3px 0 4px rgba(0, 0, 0, 0.05);
}

.center-line .scroll-icon {
    bottom: 0px;
    left: 50%;
    font-size: 25px;
    transform: translateX(-50%);
}

.row-1 section .icon {
    top: 15px;
    right: -60px;
}

.row-2 section .icon {
    top: 15px;
    left: -60px;
}

.row section .details,
.row section .bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.row section .details .title {
    text-align: left;
    font-size: 1.2rem;
    font-weight: 700;
}

.row section .details .date {
    font-size: 0.9rem;
    font-weight: 700;
}

.row section .company {
    text-align: left;
}

.row section p {
    margin: 10px 0 17px 0;
    text-align: left;
    font-size: 0.9rem;
}

.row section .bottom a {
    text-decoration: none;
    background: #18d26e;
    color: #fff;
    padding: 7px 15px;
    border-radius: 5px;
    font-weight: 400;
    transition: all 0.3s ease;
}

.row section .bottom a:hover {
    transform: scale(0.97);
}


/* MEDIA QUERIES */
/* phone */
@media (max-width: 480px) {

    .timeline .center-line,
    .row section::before,
    .row section .icon {
        display: none;
    }

    .timeline .row {
        margin: 10px 0;
    }

    .page-one .header h1 {
        font-size: 2.5em;
        font-weight: 800;
    }
}


@media (max-width: 790px) {

    .timeline .center-line {
        display: none;
    }

    .timeline .row {
        margin: 30px 0;
        padding: 0 3rem;
    }

    .timeline .row section {
        width: 100%;
    }

    .row-1 section::before {
        left: -7px;
    }

    .row-1 section .icon {
        left: -60px;
    }
}


/* Laptops 1024 - 1279*/
@media screen and (min-width: 1024px) {

    .page-two {
        font-size: 1.2rem;
    }

}