/*--------------------------------------------------------------
                  # FOOTER
--------------------------------------------------------------*/

.footer-navbar {
    text-shadow: 4px 4px 4px 0 black;
    font-size: 0.8rem;
    font-weight: 700;
    background-color: transparent;
}


/* Laptops 1024 - 1279*/
@media screen and (min-width: 1024px) {
    .footer-navbar {
        font-size: 1rem;
    }
}